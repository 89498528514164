<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <v-container>
        <v-row class="my-16">
          <v-col></v-col>
          <v-col
              lg="3"
              md="3"
              sm="12">
            <v-card
                class="pa-1"
                to="/bookmarks/animation"
                width="225px"
                height="225px"
                style="box-shadow: 0 15px 35px 0 rgba(255, 198, 119, 0.4);border-radius:15px;background: linear-gradient(to bottom right, #ffdeb2 0%, #ffbe66 100%); color: white">
              <v-card-title class="ma-3" style="font-size:22px">My Animations</v-card-title>
              <v-img class="mx-6" width="76px" height="76px" src="@/assets/icons/Animation-Home.svg"></v-img>
            </v-card>
          </v-col>
          <v-col
              lg="3"
              md="3"
              sm="12">
            <v-card
                class="pa-1"
                to="/bookmarks/book"
                width="225px"
                height="225px"
                style="box-shadow: 0 15px 35px 0 rgba(78, 199, 149, 0.41);border-radius:15px;background: linear-gradient(to bottom right, #add9c7 0%, #26bf81 100%); color: white">
              <v-card-title class="ma-3" style="font-size:22px">My Pages</v-card-title>
              <v-img class="mx-6" width="67px" height="67px" src="@/assets/icons/Digital-Book.svg"></v-img>
            </v-card>
          </v-col>
          <v-col
              lg="3"
              md="3"
              sm="12">
            <v-card
                class="pa-1"
                to="/bookmarks/video"
                width="225px"
                height="225px"
                style="box-shadow: 0 15px 35px 0 rgba(255, 160, 105, 0.4);border-radius:15px;background:linear-gradient(to bottom right, #ffc6a6 0%, #ff8640 100%); color: white">
              <v-card-title class="ma-3" style="font-size:22px">My Videos</v-card-title>
              <v-img class="mx-6" width="76px" height="76px" src="@/assets/icons/Video-Home.svg"></v-img>
            </v-card>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <v-row class="my-16">
        <v-col cols="12">
          <v-card
              to="/bookmarks/animation"
              width="80%"
              height="195px"
              style="margin-left:10%;margin-right:10%;box-shadow: 0 15px 35px 0 rgba(255, 198, 119, 0.4);border-radius:15px;background: linear-gradient(to bottom right, #ffdeb2 0%, #ffbe66 100%); color: white">
            <v-card-title class="ma-3" style="font-size:22px">My Animations</v-card-title>
            <v-img class="mx-6" width="76px" height="76px" src="@/assets/icons/Animation-Home.svg"></v-img>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card
              to="/bookmarks/book"
              width="80%"
              height="195px"
              style="margin-left:10%;margin-right:10%;box-shadow: 0 15px 35px 0 rgba(78, 199, 149, 0.41);border-radius:15px;background: linear-gradient(to bottom right, #add9c7 0%, #26bf81 100%); color: white">
            <v-card-title class="ma-3" style="font-size:22px">My Pages</v-card-title>
            <v-img class="mx-6" width="67px" height="67px" src="@/assets/icons/Digital-Book.svg"></v-img>
          </v-card>
        </v-col>
        <v-col cols="12" class="mb-16">
          <v-card
              to="/bookmarks/video"
              width="80%"
              height="195px"
              style="margin-left:10%;margin-right:10%;box-shadow: 0 15px 35px 0 rgba(255, 160, 105, 0.4);border-radius:15px;background:linear-gradient(to bottom right, #ffc6a6 0%, #ff8640 100%); color: white">
            <v-card-title class="ma-3" style="font-size:22px">My Videos</v-card-title>
            <v-img class="mx-6" width="76px" height="76px" src="@/assets/icons/Video-Home.svg"></v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookmarksIndex",
  data() {
    return {
      screenType: null,
    }
  },
  mounted() {
    this.getScreenType()
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
  }
}
</script>

<style scoped>

</style>